<template>
  <div>
    <b-table
      table-class="table-head-custom table-vertical-center"
      show-empty
      :fields="fields"
      :items="items"
      :busy="isBusyStore"
      hover
      responsive
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <CommonEmployeeSummary
          :item="morphItem(data.item)"
        ></CommonEmployeeSummary>
      </template>

      <template v-slot:cell(email)="data">
        {{ data.item.email }}
      </template>

      <template v-slot:cell(role)="data">
        <span
          class="label label-lg font-weight-bold label-light-info label-inline"
          >{{ extractRole(data.item) }}</span
        >
      </template>

      <!--  begin:: actions    -->
      <template v-slot:cell(actions)="data" v-if="isSuperAdmin">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-employee button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="assignEmployeeAdmin(data.item)"
            v-b-tooltip.hover="'Assign Admin Role'"
            v-if="
              !data.item.role.includes('CORPORATE SUPER ADMIN') &&
              data.item.role.includes('Member') &&
              !data.item.role.includes('CORPORATE ADMIN')
            "
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
            </span>
          </button>
          <!-- end::: edit-employee button -->
          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="deleteEmployeeAdmin(data.item)"
            v-b-tooltip.hover="'Remove Admin Role'"
            v-if="data.item.role.includes('CORPORATE ADMIN')"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-employee button -->
        </div>
      </template>
      <!--  end:: actions    -->

      <!--     begin:: transfer -->
      <template v-slot:cell(transfers)="data" v-if="isSuperAdmin">
        <div class="d-flex flex-row justify-content-left">
          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="transferSuperAdmin(data.item)"
            v-if="!data.item.role.includes('CORPORATE SUPER ADMIN')"
            v-b-tooltip.hover="'Transfer Super Admin Role'"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/Navigation/Exchange.svg" />
            </span>
          </button>
        </div>
      </template>
      <!--      end:: transfer-->

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="No employee registered"
          text-bottom="To employee staff, click at the CREATE button"
        ></AppEmptyList>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        size="lg"
        align="right"
        class="pt-5"
        @change="changePage"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="employee-page-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";

export default {
  name: "EmployeeAdminPageTable",
  components: { AppEmptyList, AppLoader, CommonEmployeeSummary },
  props: ["reload"],
  data() {
    return {
      fields: [
        { key: "index", label: "#" },
        { key: "name", label: this.$t("EMPLOYEETABLE.FULLNAME") },
        { key: "role", label: "Role" },
        { key: "actions", label: "Admins" },
        { key: "transfers", label: "Transfer" },
      ],
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.isBusy = true;
      return this.$store
        .dispatch("fetchEmployeeAdminList")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        })
        .finally(() => (this.isBusy = false));
    },

    refresh() {
      console.log("Reload Table!");
      this.fetch();
    },

    changePage(page) {
      console.log("Change page to ", page);
      this.$store.commit("setEmployeeAdminListPage", page);
      this.fetch();
    },

    morphItem(item) {
      return {
        name: item.full_name,
        code: item.unique_code,
        email: item.email,
        image_url: item.image_url,
      };
    },

    extractRole(item) {
      if (item.role.includes("CORPORATE SUPER ADMIN")) {
        return "Super Admin";
      } else if (item.role.includes("CORPORATE ADMIN")) {
        return "Admin";
      } else {
        return "Member";
      }
    },

    assignEmployeeAdmin(row) {
      console.log("Selected: ", row.first_name);

      this.$bvModal
        .msgBoxConfirm(`This will assign ${row.first_name} admin role`, {
          title: this.$t("ALERT.SURE"),
          centered: true,
          noCloseOnBackdrop: true,
          cancelVariant: "light",
        })
        .then((value) => {
          console.log("Hmmm", value);
          if (value) {
            return new Promise((resolve, reject) => {
              this.$store
                .dispatch("assignEmployeeAdminRole", {
                  id: row.id,
                })
                .then(() => {
                  this.$bvModal.msgBoxOk("Success!!", {
                    centered: true,
                  });
                  this.refresh();
                  resolve(value);
                })
                .catch(() => {
                  console.error("Some issues ");
                  this.$bvModal.msgBoxOk("Failed!!", {
                    centered: true,
                    okVariant: "danger",
                  });
                  reject(value);
                });
            });
          }
        })
        .catch((err) => {
          console.error("Some issues bro", err);
        });
    },

    deleteEmployeeAdmin(row) {
      console.log("Selected: ", row.first_name);

      this.$bvModal
        .msgBoxConfirm(`This will delete ${row.first_name} admin role`, {
          title: this.$t("ALERT.SURE"),
          centered: true,
          noCloseOnBackdrop: true,
          cancelVariant: "light",
        })
        .then((value) => {
          console.log("Hmmm", value);
          if (value) {
            return new Promise((resolve, reject) => {
              this.$store
                .dispatch("removeEmployeeAdminRole", {
                  id: row.id,
                })
                .then(() => {
                  this.$bvModal.msgBoxOk("Success!!", {
                    centered: true,
                  });
                  this.refresh();
                  resolve(value);
                })
                .catch(() => {
                  console.error("Some issues ");
                  this.$bvModal.msgBoxOk("Failed!!", {
                    centered: true,
                    okVariant: "danger",
                  });
                  reject(value);
                });
            });
          }
        })
        .catch((err) => {
          console.error("Some issues bro", err);
        });
    },

    /**
     * Transfer super admin to member/admin.
     *
     * @param row
     */
    transferSuperAdmin(row) {
      console.log("Selected: ", row.first_name);

      this.$bvModal
        .msgBoxConfirm(
          `Are you sure to assign ${row.first_name}
           as super admin for company? If agree, you will be demoted to company admin`,
          {
            title: `Assign ${row.first_name} as Super Admin?`,
            centered: true,
            noCloseOnBackdrop: true,
            cancelVariant: "light",
          }
        )
        .then((value) => {
          console.log("Hmmm", value);
          if (value) {
            return new Promise((resolve, reject) => {
              this.$store
                .dispatch("transferSuperAdminRole", {
                  id: row.id,
                })
                .then(() => {
                  this.$bvModal.msgBoxOk("Success!!", {
                    centered: true,
                  });
                  this.refresh();
                  resolve(value);
                })
                .catch(() => {
                  console.error("Some issues ");
                  this.$bvModal.msgBoxOk("Failed!!", {
                    centered: true,
                    okVariant: "danger",
                  });
                  reject(value);
                });
            });
          }
        })
        .catch((err) => {
          console.error("Some issues bro", err);
        });
    },
  },
  watch: {
    reload() {
      this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      items: "getEmployeeAdminList",
      pagination: "getEmployeeAdminPagination",
      currentPage: "getEmployeeAdminListPage",
      isSuperAdmin: "getSuperAdminPermission",
      isBusyStore: "getEmployeeAdminListBusy",
    }),
  },
};
</script>

<style scoped></style>
