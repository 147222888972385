<template>
  <AppTableControl
    no-export
    @search-clicked="filteredRole"
    @query-debounced="searchByFirstName"
  >
    <template #filters>
      <div class="d-flex">
        <b-form-group label="Type" class="mr-3">
          <b-form-select
            v-model="type"
            :options="roleMemberTypes"
            class="form-control-solid"
          >
          </b-form-select>
        </b-form-group>
      </div>
    </template>
  </AppTableControl>
</template>

<script>
import AppTableControl from "@/modules/core/components/shared/table/AppTableControl";

export default {
  name: "EmployeeAdminTableControl",
  components: { AppTableControl },
  data() {
    return {
      roleMemberTypes: [
        { value: 1, text: "All" },
        { value: 2, text: "Super Admin" },
        { value: 3, text: "Admin" },
        { value: 4, text: "Member" },
      ],
      type: 1,
    };
  },
  methods: {
    /**
     *
     */
    filteredRole() {
      console.log("Role: ", this.type);
      this.$store.dispatch("changeEmployeeAdminListRoleFilter", {
        role: this.type,
      });

      this.$store.dispatch("fetchEmployeeAdminList");
    },

    /**
     * Search using name query.
     *
     * @param nameQuery
     */
    searchByFirstName(nameQuery) {
      this.$store.dispatch("changeEmployeeAdminListBaseFilter", {
        base: nameQuery,
      });

      this.$store.dispatch("fetchEmployeeAdminList");
    },
  },
};
</script>

<style scoped></style>
