<template>
  <div>
    <EmployeeAdminTableControl class="mb-5" />

    <AppPageCard>
      <template #card-title> Employee Admin List </template>
      <EmployeeAdminPageTable :reload="reload"></EmployeeAdminPageTable>
    </AppPageCard>
  </div>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import EmployeeAdminPageTable from "@/modules/company/components/admin/EmployeeAdminPageTable";
import EmployeeAdminTableControl from "@/modules/company/components/admin/EmployeeAdminTableControl";
export default {
  name: "EmployeeAdminPage",
  components: {
    EmployeeAdminTableControl,
    EmployeeAdminPageTable,
    AppPageCard,
  },
  data: function () {
    return {
      reload: false,
    };
  },
  methods: {
    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
